import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import ModuleMenu from '../../components/Modules/ModuleMenu'
import {HEPA_MODULE_DATA} from './../../components/Modules/module-data'
import ModuleContent from '../../components/Modules/ModuleContent'

const HepaModulePage = () => (
  <Page>
    <SEO
      title="HEPA Filter Module | Opentrons OT-2 Modular Hardware"
      description="The Opentrons HEPA Module turns your OT-2 into a clean bench, enabling automation of contamination-prone applications. It removes 99.99% of particulates."
    />
    <ModuleMenu currentUrl={HEPA_MODULE_DATA.basic.url} />
    <ModuleContent module={HEPA_MODULE_DATA} />
  </Page>
)

export default HepaModulePage
